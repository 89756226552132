<template>
  <div class="container">
    <div class="container-switch">
      <p>¿Requiere subir archivos?</p>
      <switches v-model="enabled" color="blue"></switches>
    </div>
    <UploadFile v-if="enabled == true" v-on:getURL="getURLFile" />

    <div class="container">
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="col-12">
            <b-form-input
              v-model.trim="doc.titulo"
              type="text"
              placeholder="Título del Documento"
            ></b-form-input>
          </div>
          <div class="col-12">
            <b-form-input
              v-if="enabled === false"
              v-model.trim="urlFile"
              type="url"
              placeholder="Ingresa la URL"
            ></b-form-input>
          </div>
          <div class="col-6 col-md-2">
            <b-form-input
              v-model.number="doc.anio"
              type="number"
              placeholder="Año"
            ></b-form-input>
          </div>
          <div class="col-12 col-md-2">
            <b-form-input
              v-model.number="doc.numero"
              type="number"
              placeholder="número"
            ></b-form-input>
          </div>
          <div class="col-12 col-md-4">
            <v-select
              v-model="doc.periodo"
              taggable
              push-tags
              :options="listaPeriodos"
              placeholder="Periodo"
            />
          </div>
          <div class="col-12 col-md-4">
            <v-select
              v-model="doc.tipoAuditoria"
              taggable
              push-tags
              :options="listaAuditorias"
              placeholder="Auditoria/Revisión"
            />
          </div>
        </div>
        <b-button
          class="accept"
          v-if="
            doc.titulo &&
              doc.anio &&
              doc.numero &&
              doc.periodo &&
              doc.tipoAuditoria &&
              urlFile
          "
          type="submit"
        >
          Guardar Documento
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import UploadFile from "../components/UploadFile";

export default {
  name: "NewAuditoria",
  components: {
    Switches,
    UploadFile,
  },
  props: ["docto"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      doc: {},
      urlFile: "",
      enabled: false,
      periodoArray: [],
      auditoriaArray: [],
      listaPeriodos: [],
      listaAuditorias: [],
    };
  },
  created() {
    // Creamos un array con los Tipos de Publicaciones que existen y sin duplicarlas.
    this.ref.orderBy("periodo").onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.periodoArray.push({
          periodo: doc.data().periodo,
        });
        this.auditoriaArray.push({
          tipoAuditoria: doc.data().tipoAuditoria,
        });
      });
      const myArrPeriodos = this.periodoArray;
      const myObjPeriodos = {};
      const myArrAuditorias = this.auditoriaArray;
      const myObjAuditorias = {};

      myArrPeriodos.forEach((el) => {
        // Se comprueba si el valor existe en el Objeto.
        if (!(el.periodo in myObjPeriodos)) {
          // Si no existe se crea ese valor y se añade al array final.
          myObjPeriodos[el.periodo] = true;
          this.listaPeriodos.push(el.periodo);
        }
      });
      myArrAuditorias.forEach((el) => {
        // Se comprueba si el valor existe en el Objeto.
        if (!(el.tipoAuditoria in myObjAuditorias)) {
          // Si no existe se crea ese valor y se añade al array final.
          myObjAuditorias[el.tipoAuditoria] = true;
          this.listaAuditorias.push(el.tipoAuditoria);
        }
      });
      this.periodoArray = [];
      this.auditoriaArray = [];
    });
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      (this.doc.url = this.urlFile), (this.doc.edit = false);

      this.ref
        .add(this.doc)
        .then((docRef) => {
          (this.doc.titulo = ""),
            (this.doc.anio = ""),
            (this.doc.url = ""),
            (this.doc.numero = ""),
            (this.doc.periodo = ""),
            (this.doc.tipoAuditoria = ""),
            (this.enabled = false),
            (this.edit = false),
            (this.urlFile = ""),
            (this.listaPeriodos = []),
            (this.listaAuditorias = []);
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.container-switch {
  text-align: center;
  p {
    margin-top: 0.5rem;
  }
}
</style>
