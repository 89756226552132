<template>
  <div class="container">
    <div class="container-switch">
      <p>¿Requiere subir archivos?</p>
      <switches v-model="enabled" color="blue"></switches>
    </div>

    <UploadFile v-if="enabled == true" v-on:getURL="getURLFile" />

    <div class="container">
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="col-12">
            <b-form-input
              v-model.trim="doc.titulo"
              type="text"
              placeholder="Título del Documento"
            ></b-form-input>
          </div>
          <div class="col-12">
            <b-form-input
              v-if="enabled === false"
              v-model.trim="urlFile"
              type="url"
              placeholder="Ingresa la URL"
            ></b-form-input>
          </div>
          <div class="col-6 col-md-2">
            <b-form-input
              v-model.number="doc.anio"
              type="number"
              placeholder="Año"
            ></b-form-input>
          </div>
          <div class="col-6 col-md-4">
            <b-form-select
              v-model="doc.periodicidad"
              :options="periodicidad"
              placeholder="Periodo"
            ></b-form-select>
          </div>
          <div class="col-6 col-md-4" v-if="doc.periodicidad == 'Trimestral'">
            <b-form-select
              v-model="doc.periodo"
              :options="periodoRigido"
              placeholder="Periodo"
            ></b-form-select>
          </div>
        </div>
        <b-button
          class="accept"
          v-if="doc.titulo && doc.anio && doc.periodicidad && urlFile"
          type="submit"
        >
          Guardar Documento
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import UploadFile from "../components/UploadFile";

export default {
  name: "NewDocument",
  components: {
    Switches,
    UploadFile,
  },
  props: ["docto"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      doc: {},
      urlFile: "",
      enabled: false,
      periodicidad: [
        { value: "", text: "Elija una periodicidad" },
        { value: "Anual", text: "Anual" },
        { value: "Trimestral", text: "Trimestral" },
      ],
      periodoRigido: [
        { value: "", text: "Seleccione un Periodo" },
        { value: "1er.Tri", text: "Primer Trimestre" },
        { value: "2do.Tri", text: "Segundo Trimestre" },
        { value: "3er.Tri", text: "Tercer Trimestre" },
        { value: "4to.Tri", text: "Cuarto Trimestre" },
      ],
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      (this.doc.url = this.urlFile), (this.doc.edit = false);
      if (this.doc.periodicidad == "Anual") {
        this.doc.periodo = "";
      }
      this.ref
        .add(this.doc)
        .then((docRef) => {
          (this.doc.titulo = ""),
            (this.doc.anio = ""),
            (this.doc.url = ""),
            (this.doc.periodo = ""),
            (this.doc.periodicidad = ""),
            (this.enabled = false),
            (this.edit = false),
            (this.urlFile = "");
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.container-switch {
  text-align: center;
  p {
    margin-top: 0.5rem;
  }
}
</style>
