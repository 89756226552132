<template>
  <div class="container">
    <MenuAdmin />
    <h1 class="titulo-seccion">{{ seccion.name }}</h1>
    <p class="descripcion">{{ seccion.description }}</p>

    <div class="container-button-add">
      <b-button v-if="show" v-on:click="show = !show">
        <transition name="fade">
          <span>Cancelar</span>
        </transition>
      </b-button>
      <b-button v-if="!show" v-on:click="show = !show">
        <transition name="fade">
          <span>Nuevo</span>
        </transition>
      </b-button>
    </div>

    <template v-if="seccion.typePublication == 1">
      <div class="container-new-document">
        <transition name="fade">
          <NewAuditoria v-if="show" :docto="this.$route.params.id" />
        </transition>
      </div>
      <div class="container-list-documents">
        <h3>Lista de Auditorias</h3>
        <transition name="fade">
          <ListAuditorias :docto="this.$route.params.id" />
        </transition>
      </div>
    </template>

    <template v-if="seccion.typePublication == 2">
      <div class="container-new-document">
        <transition name="fade">
          <NewDocument v-if="show" :docto="this.$route.params.id" />
        </transition>
      </div>
      <div class="container-list-documents">
        <h3>Lista de Documentos</h3>
        <transition name="fade">
          <ListDocuments :docto="this.$route.params.id" />
        </transition>
      </div>
    </template>

    <template v-if="seccion.typePublication == 3">
      <div class="container-new-document">
        <transition name="fade">
          <NewInforme v-if="show" :docto="this.$route.params.id" />
        </transition>
      </div>
      <div class="container-list-documents">
        <h3>Lista de Informes</h3>
        <transition name="fade">
          <ListInformes :docto="this.$route.params.id" />
        </transition>
      </div>
    </template>

    <template v-if="seccion.typePublication == 4">
      <div class="container-new-document">
        <transition name="fade">
          <NewLink v-if="show" :docto="this.$route.params.id" />
        </transition>
      </div>
      <div class="container-list-documents">
        <h3>Lista de Links</h3>
        <transition name="fade">
          <ListLinks :docto="this.$route.params.id" />
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import firebase from "../firebase";
import MenuAdmin from "../components/MenuAdmin";
import NewDocument from "../components/NewDocument";
import NewAuditoria from "../components/NewAuditoria";
import NewInforme from "../components/NewInforme";
import NewLink from "../components/NewLink";
import ListDocuments from "../components/ListDocuments";
import ListAuditorias from "../components/ListAuditorias";
import ListInformes from "../components/ListInformes";
import ListLinks from "../components/ListLinks";

export default {
  name: "AdminSeccion",
  components: {
    MenuAdmin,
    NewDocument,
    NewAuditoria,
    NewInforme,
    NewLink,
    ListDocuments,
    ListAuditorias,
    ListInformes,
    ListLinks,
  },
  data() {
    return {
      key: "",
      seccion: {},
      show: false,
    };
  },
  created() {
    const ref = firebase
      .firestore()
      .collection("secciones")
      .doc(this.$route.params.id);
    ref.get().then((doc) => {
      if (doc.exists) {
        this.key = doc.id;
        this.seccion = doc.data();
      } else {
        alert("No existe la sección!");
      }
    });
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.titulo-seccion {
  text-align: center;
  font-size: 1.85rem;
  font-family: $font-01;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
}
.descripcion {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.75rem;
}
.container-button-add {
  margin: 1rem;
  text-align: center;
}
.container-new-document {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.container-list-documents {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  h3 {
    font-size: 1.25rem;
    font-family: $font-01;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
