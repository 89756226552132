<template>
  <div class="container">
    <div class="container">
      <b-form @submit.prevent="onSubmit">
        <div class="form-row">
          <div class="col-12">
            <b-form-input
              v-model.trim="doc.titulo"
              type="text"
              placeholder="Título del link"
            ></b-form-input>
          </div>
          <div class="col-12">
            <b-form-input
              v-model.trim="doc.url"
              type="url"
              placeholder="Ingresa la URL"
            ></b-form-input>
          </div>
          <div class="col-6 col-md-4">
            <b-form-select
              v-model="doc.ambito"
              :options="ambito"
              placeholder="Ámbito"
            ></b-form-select>
          </div>
        </div>
        <b-button
          class="accept"
          v-if="doc.titulo && doc.url && doc.ambito"
          type="submit"
        >
          Guardar Link
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "NewLink",
  props: ["docto"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      doc: {},
      enabled: false,
      ambito: [
        { value: "", text: "Seleccione un ámbito" },
        { value: "Estatal", text: "Estatal" },
        { value: "Nacional", text: "Nacional" },
        { value: "Internacional", text: "Internacional" },
      ],
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.doc.edit = false;

      this.ref
        .add(this.doc)
        .then((docRef) => {
          (this.doc.titulo = ""), (this.doc.url = ""), (this.doc.ambito = "");
          (this.enabled = false), (this.edit = false);
        })
        .catch((error) => {
          alert("Error al agregar el documento: ", error);
        });
    },
  },
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";
.container-switch {
  text-align: center;
  p {
    margin-top: 0.5rem;
  }
}
</style>
