<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún no se han cargado documentos
    </p>
    <b-list-group v-else>
      <b-list-group-item
        v-for="i in items"
        :key="i.key"
        class="d-flex align-items-center list-documents"
      >
        <template v-if="i.edit === false">
          <span class="mr-auto">
            <strong> ({{ i.ambito }}) </strong>
            {{ i.titulo }}
          </span>

          <b-button
            class="button-link"
            :href="i.url"
            target="_blank"
            v-b-tooltip.hover
            title="Link"
          >
            <i class="fas fa-link"></i>
          </b-button>
          <b-button
            class="button-edit"
            v-b-tooltip.hover
            title="Editar"
            @click="setEditDocument(i.key)"
          >
            <i class="fas fa-pencil-alt"></i>
          </b-button>
          <b-button
            class="button-delete"
            @click.stop="deleteDocument(i.key)"
            v-b-tooltip.hover
            title="Eliminar"
          >
            <i class="far fa-trash-alt"></i>
          </b-button>
        </template>
        <div v-else class="container-edit">
          <div class="container">
            <div class="form-row">
              <div class="col-12">
                <b-form-input
                  v-model.trim="i.titulo"
                  type="text"
                  placeholder="Título del Documento"
                ></b-form-input>
              </div>
              <div class="col-12">
                <b-form-input
                  v-model.trim="i.url"
                  type="url"
                  placeholder="Ingresa la URL"
                ></b-form-input>
              </div>
              <div class="col-6">
                <b-form-select
                  v-model="i.ambito"
                  :options="ambito"
                  placeholder="Ámbito"
                ></b-form-select>
              </div>
            </div>
            <b-button class="accept mr-3" @click="setUpdateDocument(i)">
              Actualizar
            </b-button>
            <b-button
              v-b-tooltip.hover
              title="Cancelar Edición"
              @click="cancelEditDocument(i.key)"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import UploadFile from "../components/UploadFile";

export default {
  props: ["docto", "typePub"],
  components: {
    Switches,
    UploadFile,
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: {},
      showEdit: true,
      enabled: false,
      ambito: [
        { value: "", text: "Seleccione un ámbito" },
        { value: "Estatal", text: "Estatal" },
        { value: "Nacional", text: "Nacional" },
        { value: "Internacional", text: "Internacional" },
      ],
    };
  },
  created() {
    this.ref
      .orderBy("ambito")
      .orderBy("titulo")
      .onSnapshot((querySnapshot) => {
        this.items = [];
        querySnapshot.forEach((doc) => {
          this.items.push({
            key: doc.id,
            ...doc.data(),
          });
        });
        //console.log(this.items)
      });
  },
  methods: {
    setEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    setUpdateDocument(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          titulo: id.titulo,
          ambito: id.ambito,
          url: id.url,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff689b",
        cancelButtonColor: "#5ee276",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.docto)
            .collection("documentos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
  },
};
</script>
