import firebase from 'firebase/app';

import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const settings = {timestampsInSnapshots: true};

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyACs3ydgg96ehOd4PDN0nBTZ99M03Fydtg",
    authDomain: "micrositio-oic.firebaseapp.com",
    databaseURL: "https://micrositio-oic.firebaseio.com",
    projectId: "micrositio-oic",
    storageBucket: "micrositio-oic.appspot.com",
    messagingSenderId: "1082579027900",
    appId: "1:1082579027900:web:38917a0a1d1ba631aecdb9",
    measurementId: "G-M5DBSPR1ZY"
  };

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;