<template>
  <div class="shadow p-2 bg-white rounded container-countdown">
    <div class="d-flex flex-row bd-highlight vertical-center">
      <div v-if="remainTime > 1" class="align-items-center container-circulo">
        <b-spinner class="circulo-intermitente" type="grow"></b-spinner>
      </div>
      <div class="p-2 align-items-center">
        <p class="badge-fecha">{{ limit }}</p>
        <p class="nota-fecha">
          Fecha límite para presentar tu declaración de situación patrimonial y
          de intereses.
        </p>
        <p v-if="remainTime <= 1" class="nota-countdown countdown-expirado">
          Ha expirado la fecha para presentar tu declaración a tiempo.
        </p>
        <p v-else class="nota-countdown">
          Te quedan <strong>{{ days }} días</strong> y
          <strong>{{ hours }}h:{{ minutes }}m:{{ seconds }}s</strong> para
          cumplir a tiempo.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      limit: "",
      remainTime: 0
    };
  },
  mounted() {
    const timerUpdate = setInterval(() => {
      let now = new Date();
      let deadline = "May 31 2024 23:59:00 GMT-0500";
      this.remainTime = (new Date(deadline) - now + 1000) / 1000;
      this.limit = moment(deadline).format("DD-MMMM-YYYY | hh:mm a");
      this.seconds = ("0" + Math.floor(this.remainTime % 60)).slice(-2);
      this.minutes = ("0" + Math.floor((this.remainTime / 60) % 60)).slice(-2);
      this.hours = ("0" + Math.floor((this.remainTime / 3600) % 24)).slice(-2);
      this.days = Math.floor(this.remainTime / (3600 * 24));

      // console.log(`now: ${ now } ... deadline: ${ deadline } ... this.remainTime: ${this.remainTime}`);

      if (this.remainTime <= 1) {
        clearInterval(timerUpdate);
      }
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.container-countdown {
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 280px;
  @include media-breakpoint-up(sm) {
    width: 380px;
  }
  @include media-breakpoint-up(lg) {
    margin: 0;
    
  }
  .container-circulo {
    padding: 0.1rem;
    @include media-breakpoint-up(sm) {
      padding: 0.5rem;
    }
    .circulo-intermitente {
      background: #99d646;
    }
  }

  .nota-fecha,
  .nota-countdown {
    margin-bottom: 0;
  }

  .nota-fecha {
    font-size: 0.55rem;
    @include media-breakpoint-up(sm) {
      font-size: 0.8rem;
    }
  }

  .nota-countdown {
    font-size: 0.5rem;
    @include media-breakpoint-up(sm) {
      font-size: 0.7rem;
    }
  }

  .badge-fecha {
    margin-bottom: 0.35rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  .countdown-expirado {
    color: $color-01;
  }
}
</style>
