<template>
  <b-list-group>
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado Secciones
    </p>
    <b-list-group-item
      v-else
      v-for="i in items"
      :key="i.key"
      class="d-flex align-items-center list-documents"
    >
      <template v-if="i.edit === false">
        <b-avatar variant="info" :src="i.logo" class="mr-3"></b-avatar>
        <span class="mr-auto">{{ i.name }}</span>

        <b-button
          class="button-link"
          v-if="i.tipo == 1"
          :href="i.url"
          target="_blank"
          v-b-tooltip.hover
          title="Link"
        >
          <i class="fas fa-link"></i>
        </b-button>

        <b-button
          class="button-link"
          v-if="i.tipo == 2"
          @click.stop="details(i.key)"
          v-b-tooltip.hover
          title="Sección"
        >
          <i class="fas fa-list-ul"></i>
        </b-button>
        <b-button
          class="button-edit"
          v-b-tooltip.hover
          title="Editar"
          @click="setEditSection(i.key)"
        >
          <i class="fas fa-pencil-alt"></i>
        </b-button>

        <b-button
          class="button-delete"
          @click.stop="deleteSection(i.key)"
          v-b-tooltip.hover
          title="Eliminar"
        >
          <i class="far fa-trash-alt"></i>
        </b-button>
      </template>
      <div v-else class="container-edit">
        <div class="container-switch">
          <p>¿Requiere cambiar el logo?</p>
          <switches v-model="enabled" color="blue"></switches>
        </div>
        <UploadLogo v-if="enabled" v-on:getURL="getURLImagen" />
        <div class="container btn-sm pb-3 text-center">
          <b-button v-if="urlImagen" class="accept" @click="setUpdateLogo(i)">
            Guardar Logo
          </b-button>
        </div>
        <div class="container">
          <div class="form-row">
            <div class="col-12">
              <div class="p-2 d-flex justify-content-center">
                <img class="logo" :src="i.logo" />
              </div>
              <b-form-input
                class="d-none"
                v-model="i.logo"
                type="url"
                placeholder="URL Logo"
              ></b-form-input>
            </div>
            <div class="col-12">
              <b-form-input
                v-model.trim="i.name"
                type="text"
                placeholder="Nombre de la sección"
              ></b-form-input>
            </div>
            <div class="col-12">
              <b-form-input
                v-model.trim="i.description"
                type="text"
                placeholder="Ingresa una breve descripción"
              ></b-form-input>
            </div>
            <div class="col-6 col-md-2">
              <b-form-select
                v-model="i.tipo"
                :options="options"
                placeholder="Tipo de Sección"
              ></b-form-select>
            </div>
            <div class="col-12 col-md-8" v-if="i.tipo == 1">
              <b-form-input
                v-model.trim="i.url"
                type="url"
                placeholder="Ingresa la URL"
              ></b-form-input>
            </div>
            <div class="col-12 d-none" v-else>
              <b-form-input
                v-model.trim="i.url"
                type="url"
                placeholder="Ingresa la URL"
              ></b-form-input>
            </div>
            <div class="col-12 col-md-8" v-if="i.tipo == 2">
              <b-form-select
                v-model="i.typePublication"
                :options="typePub"
                placeholder="Tipo de Sección"
              ></b-form-select>
            </div>
            <div class="col-2">
              <b-form-input
                v-model.number="i.orden"
                type="number"
                placeholder="Orden"
              ></b-form-input>
            </div>
          </div>
          <b-button class="accept" @click="setUpdateSection(i)">
            Guardar Documento
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Cancelar Edición"
            @click="cancelEditSection(i.key)"
          >
            Cancelar
          </b-button>
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import firebase from "../firebase";
import router from "../router";
import UploadLogo from "../components/UploadLogo";
import Switches from "vue-switches";

export default {
  components: {
    UploadLogo,
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore().collection("secciones"),
      items: {},
      typePub: [
        { value: null, text: "Tipo de Publicaciones" },
        { value: 1, text: "Publicación de Auditorias" },
        { value: 2, text: "Publicación de Documentos" },
        { value: 3, text: "Publicación de Informes" },
        { value: 4, text: "Publicación de Links" },
      ],
      enabled: false,
      options: [
        { value: null, text: "Tipo de Sección" },
        { value: 1, text: "Link" },
        { value: 2, text: "Sección" },
      ],
      urlImagen: null,
      url: "",
      logo: "",
      logourl: "",
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          ...doc.data(),
        });
      });
      //console.log(this.items)
    });
  },
  methods: {
    details(seccion) {
      router.push({ name: "AdminSeccion", params: { id: seccion } });
    },
    setEditSection(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se Actualizó la sección")
        })
        .catch((error) => {
          alert("Error al actualizar la sección. ", error);
        });
    },
    setUpdateLogo(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          logo: this.urlImagen,
        })
        .then(() => {
          //console.log("Se Actualizó el logo")
          this.urlImagen = "";
          this.enabled = false;
        })
        .catch((error) => {
          alert("Error al actualizar el logo. ", error);
        });
    },
    setUpdateSection(id) {
      const updateRef = this.ref.doc(id.key);
      if (id.tipo === 1) {
        this.url = id.url;
        id.typePublication = null;
      } else {
        this.url = "";
      }
      updateRef
        .update({
          name: id.name,
          description: id.description,
          tipo: id.tipo,
          orden: id.orden,
          typePublication: id.typePublication,
          logo: id.logo,
          url: this.url,
          edit: false,
        })
        .then(() => {
          this.enabled = false;
        })
        .catch((error) => {
          alert("Error al Actualizar el documento. ", error);
        });
    },
    cancelEditSection(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteSection(id) {
      this.ref
        .doc(id)
        .collection("documentos")
        .get()
        .then((snapshot) => {
          let size = snapshot.docs.length;
          if (size != 0) {
            this.$swal.fire({
              icon: "error",
              title: "No se puede eliminar esta sección.",
              text:
                "Esta Sección continene información, asegurese de eliminar primero las publicaciones.",
            });
          } else {
            this.$swal({
              title: "¿Realmente quiere eliminar esta Sección?",
              text:
                "¡Esto eliminará todo el contenido de la sección, una vez eliminada ya no podrá recuperar la información!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#ff689b",
              cancelButtonColor: "#5ee276",
              confirmButtonText: "Si, eliminar",
              cancelButtonText: "No, cancelar",
              //closeOnCancel: true
            }).then((result) => {
              //send request to server
              if (result.isConfirmed) {
                this.ref
                  .doc(id)
                  .delete()
                  .then(() => {
                    //console.log("Eliminado")
                  })
                  .catch((error) => {
                    alert("Error removing document: ", error);
                  });
                this.$swal(
                  "Registro Eliminado",
                  "El registro ha sido eliminado con éxito.",
                  "success"
                );
              }
            });
          }
        });
    },
    getURLImagen: function(url) {
      this.urlImagen = url;
    },
  },
};
</script>
