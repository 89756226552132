<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/logo-oic.svg"
          alt=""
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div
      class="container container-welcome d-flex align-items-center justify-content-center"
    >
      <div class="row">
        <div
          class="col-sm-12 col-lg-5 d-flex align-items-center justify-content-center"
        >
          <div>
            <h1 class="title-welcome">
              <span>Bienvenidos al Micrositio del </span><br />Órgano Interno de
              Control del Poder Legislativo del Estado de Quintana Roo.
            </h1>
            <!--<Countdown />-->
          </div>
        </div>
        <div class="col-sm-12 col-lg-7">
          <div class="personal-card shadow-sm">
            <div class="fondo"></div>
              <Welcome />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid container-mision-vision shadow-sm">
      <div class="container">
        <ul class="mision-vision">
          <li>
            <h3>Misión</h3>
            <p>
              Contribuir a la mejora de la gestión institucional del Poder
              Legislativo del Estado de Quintana Roo vigilando el cumplimiento
              de los objetivos estratégicos y las funciones a cargo de las
              personas servidoras públicas, promoviendo la aplicación adecuada
              de los recursos públicos, impulsando la transparencia y la
              rendición de cuentas.
            </p>
          </li>
          <li>
            <h3>Visión</h3>
            <p>
              Consolidarnos a mediano plazo como un Órgano Interno de Control de
              vanguardia reconocido por sus buenas prácticas, la calidad de sus
              resultados y por su contribución a una gestión pública
              transparente, eficiente y eficaz con integrantes profesionales,
              capacitados y experimentados en la materia, que trabajan en equipo
              por objetivos comunes basados en los principios constitucionales
              que le permitan fortalecer la confianza de la sociedad hacia el
              ente.
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="container container-link-seccions">
      <div class="row">
        <div class="col-6 col-md-4" v-for="i in items" :key="i.key">
          <div class="box-link-seccion text-center">
            <a v-if="i.tipo == 1" :href="i.url" target="_blank">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5 class="titulo-seccion">{{ i.name }}</h5>
            </a>
            <router-link v-if="i.tipo == 2" :to="'/seccion/' + i.key">
              <img
                class="logo-seccion"
                :src="i.logo"
                :alt="'Logo de la sección' + i.name"
              />
              <h5 class="titulo-seccion">{{ i.name }}</h5>
            </router-link>
            <p>{{ i.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Welcome from "../components/Welcome.vue";
import Countdown from "../components/Countdown.vue";

export default {
    name: "Home",
    components: { 
      Countdown,
      Welcome
    },
    data() {
        return {
            show: false,
            ref: firebase.firestore().collection("secciones"),
            refAvisos: firebase.firestore().collection("avisos"),
            items: {},
            avisos: {},
            modal: false,
        };
    },
    created() {
        this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
            this.items = [];
            querySnapshot.forEach((doc) => {
                this.items.push({
                    key: doc.id,
                    name: doc.data().name,
                    description: doc.data().description,
                    tipo: doc.data().tipo,
                    logo: doc.data().logo,
                    url: doc.data().url,
                });
            });
        });
        this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
            this.avisos = [];
            querySnapshot.forEach((doc) => {
                this.avisos.push({
                    key: doc.id,
                    aviso: doc.data().aviso,
                });
            });
            if (this.avisos.length != 0) {
                this.showModal();
            }
        });
    },
    methods: {
        showModal() {
            this.$refs["modal-avisos"].show();
        },
    }
};
</script>

<style lang="scss" scope>
@import "../scss/abstracts/variables";

.container-welcome {
  height: auto;
  margin-bottom: 2rem;

  .title-welcome {
    font-family: $font-01;
    font-size: 1.75rem;
    width: auto;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1rem;
    // padding-left: 1rem;
    // padding-right: 1rem;
    // padding-bottom: 2rem;
    @include media-breakpoint-up(md) {
      width: 400px;
      font-size: 2.25rem;
      margin-bottom: 2rem;
      // padding-left: 0;
      // padding-right: 0;
      //padding-top: 3rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.8rem;
      margin-top: -2rem;
      margin-bottom: 3rem;
      text-align: left;
    }
    span {
      font-size: 1.4rem;
      font-weight: 200;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 2.2rem;
      }
    }
  }

  .personal-card {
    border: 1px solid $bg-02;
    text-align: center;
    width: auto;
    margin: 0 auto;
    .fondo {
      background: $color-02;
      height: 10px;
    }
  }
}

.container-mision-vision {
  background: $bg-03;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-top: 1px solid $bg-02;
  border-bottom: 1px solid $bg-02;
  @include media-breakpoint-up(md) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .mision-vision {
    list-style: none;
    padding-left: 0;

    li {
      padding: 1.5rem 0.5rem;
      border-top: 1px solid $bg-02;
      &:first-child {
        border-top: none;
      }
      @include media-breakpoint-up(md) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
        display: inline-block;
        border-top: none;
        border-left: 1px solid $bg-02;
        &:first-child {
          border-left: none;
        }
      }

      h3 {
        font-family: $font-01;
        font-weight: 400;
      }
      p {
        font-family: $font-02;
        font-weight: 400;
        color: $color-02;
        text-align: justify;
        font-size: 0.9rem;
      }
    }
  }
}

.container-link-seccions {
  margin-top: 3rem;
  margin-bottom: 3rem;

  .box-link-seccion {
    margin: 0 auto;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    @include media-breakpoint-up(md) {
      max-width: 250px;
    }

    a {
      cursor: pointer;
      color: $color-01;
      text-decoration: none;

      &:hover {
        .titulo-seccion {
          color: $color-03;
        }
      }
      .logo-seccion {
        height: 70px;
        @include media-breakpoint-up(md) {
          height: 100px;
        }
      }
      .titulo-seccion {
        font-family: $font-01;
        font-size: 1rem;
        font-weight: 300;
        margin-top: 1.5rem;
        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }
      }
    }
    p {
      font-size: 0.6rem;
      @include media-breakpoint-up(md) {
        font-size: 0.75rem;
      }
    }
  }
}
</style>
