<template>
  <div id="app">
    <PubHeader />
    <div class="container-router-view">
      <router-view />
    </div>
    <PubFooter />
  </div>
</template>

<script>
import PubHeader from "@/components/PubHeader";
import PubFooter from "@/components/PubFooter";

export default {
  components: {
    PubHeader,
    PubFooter,
  },
};
</script>
