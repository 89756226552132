<template>
  <div class="data-card">
    <p>
      El <strong>Órgano Interno de Control del Poder Legislativo del Estado de Quintana Roo</strong>, es un órgano
      dotado de autonomía técnica y de gestión, adscrito administrativamente al pleno, sin que ello se traduzca en
      subordinación alguna.
    </p>
    <p>
      Nuestro objetivo es prevenir, investigar, detectar, evaluar y sancionar las faltas administrativas y hechos de
      corrupción de las personas servidoras públicas para fortalecer el funcionamiento del control interno del Poder
      Legislativo, conforme a la normatividad de los Sistemas Nacional y Estatal anticorrupción y la Ley General de
      Responsabilidades Administrativas.
    </p>
    <p>
      Para nosotros es importante la participación ciudadana, por lo que en esta sección encontrarás la opción de
      presentar quejas, denuncias y/o sugerencia en contra de personas servidoras públicas del ente, con el fin de
      detectar áreas de oportunidad, tomar medidas preventivas y correctivas para perfeccionar nuestro funcionamiento
      con el fin de brindar un servicio de calidad al Poder Legislativo y a la ciudadanía.
    </p>
    <p>
      En complemento, ponemos a disposición enlaces de interés para que las personas servidoras públicas del Poder
      Legislativo del Estado de Quintana Roo puedan cumplir con sus obligaciones en materia de declaraciones
      patrimoniales y/o entrega – recepción.
    </p>
    <p>
      De esta forma contribuimos al cumplimiento de nuestro encargo y se reafirma el compromiso del Poder Legislativo
      del Estado de Quintana Roo de impulsar los principios de Parlamento Abierto, para avanzar en el desarrollo de una
      sociedad más abierta, plural, crítica, informada y participativa.
    </p>
    <p>En caso de que necesites información precisa al respecto, por favor envíanos un correo electrónico
      <a href="mailto:oic.ple@congresoqroo.gob.mx">oic.ple@congresoqroo.gob.mx</a>
    </p>
    <br />
    <h5>Atentamente</h5>
    <h4>Licdo. Luis Jorge Mezquita Altamirano.</h4>
    <h5>Titular del Órgano Interno de Control</h5>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "Welcome",
};
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  .data-card {
    padding: 1rem;
    background: $bg-03;

    @include media-breakpoint-up(md) {
      padding: 2rem;
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 4px solid white;
      margin-top: -5rem;
      margin-bottom: 1rem;
    }

    h4,
    h5,
    p {
      font-family: $font-02;
      font-weight: 400;
    }

    h4 {
      text-transform: uppercase;
      font-size: 1rem;

      @include media-breakpoint-up(md) {
        font-size: 1.1rem;
      }
    }

    h5 {
      color: $color-03;
      font-size: 0.8rem;

      @include media-breakpoint-up(md) {
        font-size: 0.9rem;
      }
    }

    p {
      font-weight: 300;
      color: $color-02;
      font-size: 0.8rem;
      text-align: justify;

      @include media-breakpoint-up(lg) {
        font-size: 0.85rem;
      }
    }

    a {
      color: $color-03;
    }
  }
</style>
