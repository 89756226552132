import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "firebase";
import BootstrapVue from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import vSelect from "vue-select";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);

import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import "./scss/main.scss";

Vue.config.productionTip = false;

firebase.auth().onAuthStateChanged((user) => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount("#app");
});
