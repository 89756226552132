<template>
  <div>
    <p v-if="items.length == 0" class="text-center">
      Aún se han cargado documentos
    </p>
    <div v-else class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Descripción</th>
            <th scope="col">Número</th>
            <th scope="col">Tipo</th>
            <th scope="col">Periodo</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.key">
            <template v-if="item.edit === false">
              <td>{{ item.titulo }}</td>
              <td>{{ item.numero }}/{{ item.anio }}</td>
              <td>{{ item.tipoAuditoria }}</td>
              <td>{{ item.periodo }}</td>
              <td>
                <b-button
                  class="button-link"
                  :href="item.url"
                  target="_blank"
                  v-b-tooltip.hover
                  title="Link"
                >
                  <i class="fas fa-link"></i>
                </b-button>
                <b-button
                  class="button-edit"
                  v-b-tooltip.hover
                  title="Editar"
                  @click="setEditDocument(item.key)"
                >
                  <i class="fas fa-pencil-alt"></i>
                </b-button>
                <b-button
                  class="button-delete"
                  @click.stop="deleteDocument(item.key)"
                  v-b-tooltip.hover
                  title="Eliminar"
                >
                  <i class="far fa-trash-alt"></i>
                </b-button>
              </td>
            </template>
            <td v-else colspan="6">
              <div class="container-edit">
                <div class="container-switch">
                  <p>¿Requiere cambiar el documento?</p>
                  <switches v-model="enabled" color="blue"></switches>
                </div>
                <UploadFile v-if="enabled" v-on:getURL="getURLFile" />
                <div class="container btn-sm pb-3 text-center">
                  <b-button
                    v-if="urlFile"
                    class="accept"
                    @click="setUpdateFile(item)"
                  >
                    Actualizar Archivo
                  </b-button>
                </div>

                <div class="container">
                  <div class="form-row">
                    <div class="col-12">
                      <b-form-input
                        v-model.trim="item.titulo"
                        type="text"
                        placeholder="Título del Documento"
                      ></b-form-input>
                    </div>
                    <div class="col-12">
                      <b-form-input
                        v-model.trim="item.url"
                        type="url"
                        placeholder="Ingresa la URL"
                      ></b-form-input>
                    </div>
                    <div class="col-6 col-md-2">
                      <b-form-input
                        v-model.number="item.anio"
                        type="number"
                        placeholder="Año"
                      ></b-form-input>
                    </div>
                    <div class="col-12 col-md-2">
                      <b-form-input
                        v-model.number="item.numero"
                        type="number"
                        placeholder="Número"
                      ></b-form-input>
                    </div>
                    <div class="col-12 col-md-4">
                      <v-select
                        v-model="item.periodo"
                        taggable
                        push-tags
                        :options="listaPeriodos"
                        placeholder="Periodo"
                      />
                    </div>
                    <div class="col-12 col-md-4">
                      <v-select
                        v-model="item.tipoAuditoria"
                        taggable
                        push-tags
                        :options="listaAuditorias"
                        placeholder="Auditoria/Revisión"
                      />
                    </div>
                  </div>
                  <b-button
                    class="accept mr-3"
                    title="Actualizar"
                    @click="setUpdateDocument(item)"
                  >
                    Actualizar
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    title="Cancelar Edición"
                    @click="cancelEditDocument(item.key)"
                  >
                    Cancelar
                  </b-button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import UploadFile from "../components/UploadFile";

export default {
  props: ["docto"],
  name: "ListAuditorias",
  components: {
    Switches,
    UploadFile,
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.docto)
        .collection("documentos"),
      items: {},
      showEdit: true,
      enabled: false,
      item: {},
      typeURL: "link",
      urlFile: "",
      periodoArray: [],
      auditoriaArray: [],
      listaPeriodos: [],
      listaAuditorias: [],
    };
  },
  created() {
    this.ref.orderBy("periodo").onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.periodoArray.push({
          periodo: doc.data().periodo,
        });
        this.auditoriaArray.push({
          tipoAuditoria: doc.data().tipoAuditoria,
        });
      });
      const myArrPeriodos = this.periodoArray;
      const myObjPeriodos = {};
      const myArrAuditorias = this.auditoriaArray;
      const myObjAuditorias = {};

      myArrPeriodos.forEach((el) => {
        // Se comprueba si el valor existe en el Objeto.
        if (!(el.periodo in myObjPeriodos)) {
          // Si no existe se crea ese valor y se añade al array final.
          myObjPeriodos[el.periodo] = true;
          this.listaPeriodos.push(el.periodo);
        }
      });
      myArrAuditorias.forEach((el) => {
        // Se comprueba si el valor existe en el Objeto.
        if (!(el.tipoAuditoria in myObjAuditorias)) {
          // Si no existe se crea ese valor y se añade al array final.
          myObjAuditorias[el.tipoAuditoria] = true;
          this.listaAuditorias.push(el.tipoAuditoria);
        }
      });
      this.periodoArray = [];
      this.auditoriaArray = [];
    });

    this.ref
      .orderBy("anio", "desc")
      .orderBy("numero")
      .onSnapshot((querySnapshot) => {
        this.items = [];
        querySnapshot.forEach((doc) => {
          this.items.push({
            key: doc.id,
            titulo: doc.data().titulo,
            anio: doc.data().anio,
            url: doc.data().url,
            edit: doc.data().edit,
            numero: doc.data().numero,
            periodo: doc.data().periodo,
            tipoAuditoria: doc.data().tipoAuditoria,
          });
        });
        //console.log(this.items)
      });
  },
  methods: {
    setEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: true,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
      (this.listaPeriodos = []), (this.listaAuditorias = []);
    },
    setUpdateFile(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          url: this.urlFile,
        })
        .then(() => {
          //console.log("Se Actualizó el logo")
          this.urlFile = "";
          this.enabled = false;
        })
        .catch((error) => {
          alert("Error al actualizar el logo. ", error);
        });
    },
    setUpdateDocument(id) {
      const updateRef = this.ref.doc(id.key);
      updateRef
        .update({
          titulo: id.titulo,
          anio: id.anio,
          url: id.url,
          numero: id.numero,
          periodo: id.periodo,
          tipoAuditoria: id.tipoAuditoria,
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
          this.enabled === false;
        })
        .catch((error) => {
          alert("Error al Actuaizar el documento: ", error);
        });
    },
    cancelEditDocument(id) {
      const updateRef = this.ref.doc(id);
      updateRef
        .update({
          edit: false,
        })
        .then(() => {
          //console.log("Se actualizó")
        })
        .catch((error) => {
          alert("Error al cancelar la Edición del Documento: ", error);
        });
    },
    deleteDocument(id) {
      this.$swal({
        title: "¿Realmente quiere eliminar este Documento?",
        text: "¡Una vez eliminado ya no podrá recuperar la información!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#ff689b",
        cancelButtonColor: "#5ee276",
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        //closeOnCancel: true
      }).then((result) => {
        //send request to server
        if (result.isConfirmed) {
          firebase
            .firestore()
            .collection("secciones")
            .doc(this.docto)
            .collection("documentos")
            .doc(id)
            .delete()
            .then(() => {
              //console.log("Eliminado")
            })
            .catch((error) => {
              alert("Error al eliminar el documento: ", error);
            });
          this.$swal(
            "Registro Eliminado",
            "El registro ha sido eliminado con éxito.",
            "success"
          );
        }
      });
    },
    getURLFile: function(url) {
      this.urlFile = url;
    },
  },
};
</script>
