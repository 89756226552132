<template>
  <div class="contanier">
    <b-form @submit.prevent="onSubmit">
      <div class="form-row">
        <div class="col-12">
          <vue-editor id="description" v-model="avisos.aviso" />
        </div>
        <div class="col-4">
          <p>¿Activar Aviso?</p>
          <switches v-model="avisos.activo" color="blue"></switches>
        </div>
      </div>
      <b-button class="accept" v-if="avisos.aviso" type="submit">
        Guardar Aviso
      </b-button>
    </b-form>
  </div>
</template>

<script>
import firebase from "../firebase";
import Switches from "vue-switches";
import { VueEditor } from "vue2-editor";

export default {
  name: "NewAviso",
  components: {
    VueEditor,
    Switches,
  },
  data() {
    return {
      ref: firebase.firestore().collection("avisos"),
      avisos: {},
      selected: "",
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.avisos.edit = false;
      this.ref
        .add(this.avisos)
        .then((docRef) => {
          (this.avisos.aviso = ""), (this.avisos.activo = "");
        })
        .catch((error) => {
          alert("Error adding document: ", error);
        });
    },
  },
};
</script>
